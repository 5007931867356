<template>
  <div class="wrapper" :style="{ 'background-image': `url(${pictureUrl})`,'background-repeat': 'no-repeat','background-position':'center','background-size': 'cover'}">
            <div class="container p-0">
                <div class="header">
                    <div class="logo"><img src="@/assets/images/header.jpg"></div>
                </div>
            </div>
            <div class="container main-bg p-0">
                <div class="main">
                  <div class="content">
                        <div class="l-content">
                            <div>
                                <div class="now-result mb-2 pt-2">
                                    <div class="result-head p-2 mb-2">
                                        <h2>ງວດປະຈຳວັນທີ່ {{ (this.datetime)?formatDate(this.datetime):formatDate(this.first.datetime) }}</h2>
                                    </div>
                                    <div class="result-body px-4">
                                        <div class="result-top">
                                            <h1 class="mb-0">
                                                <span>
                                                <span id="dice5">{{(this.first && this.first.five)?this.first.five.charAt(0):''}}</span>
                                                <span id="dice4">{{(this.first && this.first.five)?this.first.five.charAt(1):''}}</span>
                                                <span id="dice3">{{(this.first && this.first.five)?this.first.five.charAt(2):''}}</span>
                                                <span id="dice2">{{(this.first && this.first.five)?this.first.five.charAt(3):''}}</span>
                                                <span id="dice1">{{(this.first && this.first.five)?this.first.five.charAt(4):''}}</span>
                                                </span>
                                            </h1>
                                            <h3 class="mb-0 mt-2">ເວລາອອກເລກ {{ formatTime(this.first.datetime) }}</h3>
                                        </div>
                                    </div>
                                    <div class="result-body px-4 pt-2 pb-3">
                                        <table class="table table-bordered" v-if="this.first && this.first.five">
                                            <tr>
                                                <td style="width: 50%;">
                                                    <div class="left">ເລກ 5 ໂຕ</div>
                                                </td>
                                                <td>
                                                    <div class="right">{{(!this.AniDiceRandom)?this.first.five:'-'}}</div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div class="left">ເລກ 4 ໂຕ</div>
                                                </td>
                                                <td>
                                                    <div class="right" v-if="!this.AniDiceRandom">{{this.first.five.charAt(1)}}{{this.first.five.charAt(2)}}{{this.first.five.charAt(3)}}{{this.first.five.charAt(4)}}</div>
                                                    <div v-else>-</div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div class="left">ເລກ 3 ໂຕ</div>
                                                </td>
                                                <td>
                                                    <div class="right" v-if="!this.AniDiceRandom">{{this.first.five.charAt(2)}}{{this.first.five.charAt(3)}}{{this.first.five.charAt(4)}}</div>
                                                    <div v-else>-</div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div class="left">ເລກ 2 ໂຕ</div>
                                                </td>
                                                <td>
                                                    <div class="right" v-if="!this.AniDiceRandom">{{this.first.five.charAt(3)}}{{this.first.five.charAt(4)}}</div>
                                                    <div v-else>-</div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div class="left">ເລກ 1 ໂຕ</div>
                                                </td>
                                                <td>
                                                    <div class="right" v-if="!this.AniDiceRandom">{{this.first.five.charAt(4)}}</div>
                                                    <div v-else>-</div>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div class="history-results mb-2 mt-3">
                                <div class="his-head-bg mb-2">
                                    <h2 class="m-0">ຜົນການອອກເລກລາງວັນ</h2>
                                </div>
                                <table class="table table-bordered mb-1 table-hover">
                                    <thead>
                                        <tr>
                                            <td>ງວດວັນທີ່</td>
                                            <td width="16%">ເລກ 5 ໂຕ</td>
                                            <td width="16%">ເລກ 4 ໂຕ</td>
                                            <td width="16%">ເລກ 3 ໂຕ</td>
                                            <td width="16%">ເລກ 2 ໂຕ</td>
                                            <td width="16%">ເລກ 1 ໂຕ</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item,index) in data">
                                            <td><span>{{formatDate(item.datetime)}}</span></td>
                                            <td>{{ item.five }}</td>
                                            <td>{{ item.five.charAt(1)}}{{ item.five.charAt(2)}}{{ item.five.charAt(3)}}{{ item.five.charAt(4)}}</td>
                                            <td>{{ item.five.charAt(2)}}{{ item.five.charAt(3)}}{{ item.five.charAt(4)}}</td>
                                            <td>{{ item.five.charAt(3)}}{{ item.five.charAt(4)}}</td>
                                            <td>{{ item.five.charAt(4)}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="r-content">
                            <div class="banner banner-bottom mt-2" v-for="(item,index) in banner"><img :src="item.image" class="banner"></div>
                        </div>
                    </div>
                    <div class="bottom-banner"><img src="@/assets/images/ce4adcfd-7301-42ed-82c8-af05b9416992.jpg"></div>
                    <div class="footer">
                        <div class="footer-inner"> Copyright ©{{getFullYear()}} - Vientiane-VISA.com </div>
                    </div>
                </div>
            </div>
        </div>
</template>

<script>
import firebase from 'firebase/app'
import moment from 'moment-timezone'
import axios from 'axios'
export default {
  name: 'Home',
  mounted () {
    this.getBackGround()
    this.getLotto()
    this.getBanners()
  },
  computed: {
  },
  data () {
    return {
        AniDiceRandom:false,
      dic: {
        1: 0,
        2: 0,
        3: 0,
        4: 0,
        5: 0,
      },
      pictureUrl: '',
      first: '',
      data: [],
      banner: [],
      seconds: 0,
      datetime:'',
    }
  },
  methods: {
    async getTimeServer() {
      try {
        const response = await axios.get('https://worldtimeapi.org/api/timezone/Asia/Bangkok');
        return response.data.datetime;
      } catch (error){
        try {
          const response = await axios.get('https://us-central1-shiba-88.cloudfunctions.net/api/gettimezone');
          return response.data.date;
        } catch (error) {
          console.error(error);
          throw error; // คุณสามารถเลือกจะรีเซ็ตและจัดการข้อผิดพลาดตามความต้องการ
        }
      }
    },
    AniDice (dic) {
      this.dic[dic] = setInterval(this.rolldice, 100, dic)
    },
    rolldice (dic) {
      var ranNum = Math.floor(1 + Math.random() * 6)
      document.getElementById('dice' + dic).innerHTML = ranNum
    },
    stopDice (dic) {
      clearInterval(this.dic[dic])
      if (dic == 1) {
        document.getElementById('dice' + dic).innerHTML = this.first.five.charAt(4)
      } else if (dic == 2) {
        document.getElementById('dice' + dic).innerHTML = this.first.five.charAt(3)
      } else if (dic == 3) {
        document.getElementById('dice' + dic).innerHTML = this.first.five.charAt(2)
      } else if (dic == 4) {
        document.getElementById('dice' + dic).innerHTML = this.first.five.charAt(1)
      } else if (dic == 5) {
        document.getElementById('dice' + dic).innerHTML = this.first.five.charAt(0)
      }
    },
    async getBackGround () {
      const background = (await firebase.database().ref('/background4').once('value')).val()
      if(background)
      this.pictureUrl = background.image
    },
    async getBanners () {
      const banners = (await firebase.database().ref('/banners/4').once('value')).val()
      this.banner = _.orderBy(banners, ['order'], ['asc'])
    },
    async getLotto () {
      const timezone = await this.getTimeServer()
      let data = (await firebase.database().ref('/huay/4').orderByChild('datetime').limitToLast(20).once('value')).val()
      data = _.orderBy(data, ['datetime'], ['desc'])
      if (data) {
        for (const x in data) {
          const d = data[x].datetime.split(' ')
          const date = d[0].split('-')
          const time = d[1].split(':')
          const datetime = Number(date[0] + '' + date[1] + '' + date[2] + '' + time[0] + '' + time[1])
          if (datetime > Number(moment(timezone).format('YYYYMMDDHHmm'))) {
            continue
          }
          if (!this.first) { this.first = data[x] }
          this.data.push(data[x])
        }
      }
      setInterval(this.getLastData, 1000)
    },
    getFullYear () {
      return moment().tz('Asia/Bangkok').format('YYYY')
    },
    convertToLaosMonth(month) {
      switch(month) {
        case "January":
          return "ມັງກອນ";
        case "February":
          return "ກຸມພາ";
        case "March":
          return "ມີນາ";
        case "April":
          return "ເມສາ";
        case "May":
          return "ພຶດສະພາ";
        case "June":
          return "ມິຖຸນາ";
        case "July":
          return "ກໍລະກົດ";
        case "August":
          return "ສິງຫາ";
        case "September":
          return "ກັນຍາ";
        case "October":
          return "ຕຸລາ";
        case "November":
          return "ພະຈິກ";
        case "December":
          return "ທັນວາ";
        default:
          return month;
      }
    },
    formatDate (date) {
      const dd = moment(date).tz('Asia/Bangkok').format('DD')
      const year = moment(date).tz('Asia/Bangkok').format('YYYY')
      const month = this.convertToLaosMonth(moment(date).tz('Asia/Bangkok').format('MMMM'))
      return dd + ' ' + month + ' ' + year
    },
    formatTime(date){
      return moment(date).tz('Asia/Bangkok').format('HH:mm')
    },
    dateToday () {
      return moment().tz('Asia/Bangkok').format('dddd, DD MMMM YYYY')
    },
    async getLastData () {
      const timezone = await this.getTimeServer()
      const datas = (await firebase.database().ref('/huay/4').orderByChild('datetime').startAt(moment(timezone).format('YYYY-MM-DD HH:mm')).limitToFirst(1).once('value')).val()
      if (datas) {
        for (const x in datas) {
          const data = datas[x]
          if (data && data.datetime === moment(timezone).format('YYYY-MM-DD HH:mm')) {
            if (this.first.five !== data.five) {
              this.first = data
              setTimeout(() => {
                this.stopDice(1)
                this.AniDiceRandom = false
              }, Number(6 + '000') + 1000)
              setTimeout(() => {
                this.stopDice(2)
              }, Number(5 + '000') + 1000)
              setTimeout(() => {
                this.stopDice(3)
              }, Number(4 + '000') + 1000)
              setTimeout(() => {
                this.stopDice(4)
              }, Number(3 + '000') + 1000)
              setTimeout(() => {
                this.stopDice(5)
              }, Number(2 + '000') + 1000)
            }
          } else {
            const t1 = moment().tz('Asia/Bangkok')
            const t2 = moment(data.datetime).tz('Asia/Bangkok')
            const diff = t2.diff(t1, 'seconds')
            // $('#clock-lottery').FlipClock(diff, {
            //   countdown: true
            // });
            if(diff <= 1800 && this.AniDiceRandom === false){
                this.AniDiceRandom = true;
                this.AniDice(1)
                this.AniDice(2)
                this.AniDice(3)
                this.AniDice(4)
                this.AniDice(5)
                this.datetime = data.datetime
            }
          }
        }
      }
    }
  }
}
</script>
